import {Injectable} from '@angular/core';
import FileSaver from 'file-saver';

import {FileResponse} from '@heidelberg/vmi-subscription-api-client';

import {MimeType} from '@vmi/shared-models';

@Injectable({
    providedIn: 'root',
})
export class FileDownloaderService {
    public download(file: FileResponse, fileName: string, mimeType: MimeType) {
        const blob: Blob = new Blob([file.data], { type: mimeType });

        FileSaver.saveAs(blob, fileName);
    }
}
