import {inject, InjectionToken} from '@angular/core';

import {WINDOW} from "@vmi/injection-tokens";

export const SESSION_STORAGE = new InjectionToken<Storage>('Wrapper around sessionStorage', {
  providedIn: 'root',
  factory: () => {
    const window = inject(WINDOW);
    return window.sessionStorage;
  },
});
