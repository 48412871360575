import {Inject, Injectable} from '@angular/core';

import {HEI_CC_URL, LOCATION} from '@vmi/injection-tokens';

@Injectable({
    providedIn: 'root',
})
export class HplusRedirectionService {
    constructor(
        @Inject(LOCATION) private readonly location: Location,
        @Inject(HEI_CC_URL) private readonly heiCcUrl: string
    ) {}

    public redirect(): void {
        this.location.assign(this.heiCcUrl);
    }
}
