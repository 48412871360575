import {ProductDto} from '@heidelberg/vmi-subscription-api-client';

export interface ExtractedScannedProductData {
    id: string | undefined;
    shelf: string | undefined;
}

export function isConsumptionOngoing(product: ProductDto): boolean {
    if (product.consumption === undefined || product.consumption === null) {
        return false;
    }

    return product.consumption !== 0;
}

export function isStockCountingOngoing(product: ProductDto): boolean {
    if (product.ongoingStockCounting === undefined || product.ongoingStockCounting === null) {
        return false;
    }

    return product.ongoingStockCounting >= 0;
}

export function extractProductDataFromScannedValue(scannedValue: string): ExtractedScannedProductData {
    const SCAN_DELIMITER = ',';
    const ID_PREFIX = 'P=';
    const SHELF_PREFIX = 'S=';

    const scannedValues = scannedValue.split(SCAN_DELIMITER);

    const idScan = scannedValues.find((value) => value.toUpperCase().startsWith(ID_PREFIX));
    const shelfScan = scannedValues.find((value) => value.toUpperCase().startsWith(SHELF_PREFIX));

    const extractValue = (value: string) => {
        const splitValues = value.split('=');
        return splitValues.length > 1 ? splitValues[1] : undefined;
    };

    return {
        id: idScan ? extractValue(idScan) : undefined,
        shelf: shelfScan ? extractValue(shelfScan) : undefined,
    };
}

export function findProductBasedOnQrCodeScan(products: ProductDto[], scannedValue: string): ProductDto | undefined {
    const extractedValues = extractProductDataFromScannedValue(scannedValue);

    const id = extractedValues.id;
    if (id) {
        return products.find((product) => product.id === id);
    }

    const shelf = extractedValues.shelf;
    if (shelf) {
        return products.find((product) => product.shelf === shelf);
    }

    return undefined;
}
