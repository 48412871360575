import {inject, InjectionToken} from '@angular/core';

import {WINDOW} from '@vmi/injection-tokens';

export const LOCATION = new InjectionToken<Location>('Wrapper around Window`s location object', {
    providedIn: 'root',
    factory: () => {
        const window = inject(WINDOW);
        return window.location;
    },
});
