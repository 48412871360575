import {FormControl} from '@angular/forms';

export type ValidationWarnings = {
    [key: string]: any;
};

export class FormControlWarn extends FormControl {
    warnings: ValidationWarnings | null = null;

    public hasWarning(warningCode: string): boolean {
        return !!this.warnings && Object.keys(this.warnings).includes(warningCode);
    }

    public hasWarnings(): boolean {
        return !!this.warnings;
    }
}
