/* eslint-disable  @typescript-eslint/no-explicit-any */
import {inject, InjectionToken} from '@angular/core';
import {DOCUMENT} from '@angular/common';

import {
  distinctUntilChanged,
  filter,
  fromEvent,
  map,
  MonoTypeOperatorFunction,
  Observable,
  shareReplay,
  startWith,
  takeUntil,
} from 'rxjs';

export const PAGE_VISIBILITY_CHANGE = new InjectionToken<Observable<boolean>>('Wrapper around visibilitychange event', {
    providedIn: 'root',
    factory: () => {
        const doc = inject(DOCUMENT);

        return fromEvent(doc, 'visibilitychange').pipe(
            startWith(doc.visibilityState),
            map(() => doc.visibilityState === 'visible'),
            distinctUntilChanged(),
            shareReplay(1),
        );
    },
});

export function takeUntilPageVisible(visibilityChangeNotifier$: Observable<any>): MonoTypeOperatorFunction<any> {
    return takeUntil(visibilityChangeNotifier$.pipe(filter((v) => !v)));
}
