// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { SHARED_ENVIRONMENT } from './shared-environment';

export const environment = {
    production: false,
    APP_CLIENT_ID: '7041ee61-fd20-41b0-a014-9b2f2fd443f4',
    SERVICE_URLS: {
        'subscription-service': 'https://api.vmi.stage.heidelberg.cloud/api/subscription-api',
    },
    HEIDELBERG_CC_URL: 'https://cc.stage.heidelberg.cloud',
    ...SHARED_ENVIRONMENT,
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
