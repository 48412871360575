import {Injectable} from '@angular/core';
import {BreakpointObserver} from '@angular/cdk/layout';
import {map} from 'rxjs/operators';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {debounceTime, fromEvent, startWith} from 'rxjs';

export const FOR_PHONE_ONLY = '(max-width: 767px)';

export const FOR_TABLET_UP = '(min-width: 768px)';

export const FOR_HANDSET =
    '(max-width: 599.98px) and (orientation: portrait), (max-width: 959.98px) and (orientation: landscape)';

export const FOR_DESKTOP_MEDIUM_UP = '(min-width: 1200px)';

export const FOR_DESKTOP_LARGE_UP = '(min-width: 1600px)';

export const FOR_DESKTOP_SUPER_LARGE_UP = '(min-width: 2000px)';

export const FOR_DESKTOP_SUPER_LARGE_2500PX_UP = '(min-width: 2500px)';

export const FOR_DESKTOP_SUPER_LARGE_3000PX_UP = '(min-width: 3000px)';

const FIVE_HUNDRED = 500;

@UntilDestroy()
@Injectable({
    providedIn: 'root',
})
export class ResizeService {
    constructor(private readonly breakpointObserver: BreakpointObserver) {}

    public isPhoneOnly$ = this.breakpointObserver.observe(FOR_PHONE_ONLY).pipe(
        untilDestroyed(this),
        map((result) => result.matches)
    );

    public isTabletUp$ = this.breakpointObserver.observe(FOR_TABLET_UP).pipe(
        untilDestroyed(this),
        map((result) => result.matches)
    );

    public isHandset$ = this.breakpointObserver.observe(FOR_HANDSET).pipe(
        untilDestroyed(this),
        map((result) => result.matches)
    );

    public isDesktopMediumUp$ = this.breakpointObserver.observe(FOR_DESKTOP_MEDIUM_UP).pipe(
        untilDestroyed(this),
        map((result) => result.matches)
    );

    public isDesktopLargeUp$ = this.breakpointObserver.observe(FOR_DESKTOP_LARGE_UP).pipe(
        untilDestroyed(this),
        map((result) => result.matches)
    );

    public isDesktopSuperLargeUp$ = this.breakpointObserver.observe(FOR_DESKTOP_SUPER_LARGE_UP).pipe(
        untilDestroyed(this),
        map((result) => result.matches)
    );

    public isDesktopSuperLarge2k500pxUp$ = this.breakpointObserver.observe(FOR_DESKTOP_SUPER_LARGE_2500PX_UP).pipe(
        untilDestroyed(this),
        map((result) => result.matches)
    );

    public isDesktopSuperLarge3kpxUp$ = this.breakpointObserver.observe(FOR_DESKTOP_SUPER_LARGE_3000PX_UP).pipe(
        untilDestroyed(this),
        map((result) => result.matches)
    );

    public windowSize$ = fromEvent(window, 'resize')
        .pipe(untilDestroyed(this), startWith(window.innerWidth), debounceTime(FIVE_HUNDRED))
        .pipe(map(() => window.innerWidth));

    public getNewResizeObserver(callback: (entries: ResizeObserverEntry[]) => void): ResizeObserver {
        return new ResizeObserver(callback);
    }
}
